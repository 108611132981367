import styled from "styled-components";
import {ButtonProps} from "./Button";

export const StyledButton = styled.button<Omit<ButtonProps, 'title' | 'active'> & { $active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  color: #FFF;
  background-color: #FFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  letter-spacing: 1px;

  &:hover, &:active {
    filter: brightness(110%);
  }

  &:disabled {
    background-color: #f3f3f3;
    color: #cbcbcb;
    border: none;
    pointer-events: none;
  }

  ${({theme, color}) => color && (
      color === 'errorColor' ? {backgroundColor: theme.errorColor} : 
      color === 'terracotta' ? {backgroundColor: theme.terracottaColor} : 
      color === 'green' ? {backgroundColor: theme.greenColor} :
      color === 'ultraviolet' ? {backgroundColor: theme.ultravioletColor} :
      color === 'blue' ? {backgroundColor: theme.blueColor} :
      color === 'red' ? {backgroundColor: theme.redColor} :
      color === 'terracotta-transparent' ? {
        color: theme.terracottaColor,
        border: `1px solid ${theme.terracottaColor}`
      } :
      color === 'red-transparent' ? {
          color: theme.redColor,
          border: `1px solid ${theme.redColor}`
      } :
      color === 'green-transparent' ? {
              color: theme.greenColor,
              border: `1px solid ${theme.greenColor}`
          } :        
      color === 'ultraviolet-transparent' ? {
        color: theme.ultravioletColor,
        border: `1px solid ${theme.ultravioletColor}`
      } :
        color === 'blue-transparent' ? {
        color: theme.blueColor,
        border: `1px solid ${theme.blueColor}`
      } : {}
  )}

  ${({size}) => size && (
      size === 'XXXXL' ? {width: 262, height: 42} :
      size === 'XXXL' ? {width: 262, height: 52} :
      size === 'XXL' ? {width: 232, height: 52} :
      size === 'XL' ? {width: 178, height: 44} :
      size === 'LG' ? {width: 148, height: 52} :
      size === 'MD2' ? {width: 170, height: 32} :
      size === 'MD' ? {width: 132, height: 32} :
      size === 'SM' ? {width: 85, height: 32} :
      size === 'XS' ? {width: 64, height: 32} :
      size === 'TN' ? {width: 32, height: 32} : {}
  )}

  ${({theme, variant}) => variant && (
      variant === 'secondary' ? {
        fontSize: '14px',
        letterSpacing: '1px',
        fontWeight: 700
      } :
      variant === 'aside' ? {
        border: `2px solid ${theme.greenColor}`,
        color: theme.greenColor
      } :
      variant === 'aside-black' ? {
          border: 'none',
          background: 'transparent',
          fontWeight: 400,
          color: 'rgba(255, 255, 255, 0.80)'    
      } : {}
  )}

  ${({theme, $active, variant}) => $active && ({
    backgroundColor: (variant === 'aside-black' ? theme.blueColor : theme.greenColor),
    color: theme.whiteColor,
    fontWeight: (variant === 'aside-black' ? 800 : 'inherit'),
  })}
`;

export const BthImg = styled.img<{$isLarge?: boolean}>`
    width: ${({ $isLarge = false }) => $isLarge ? '24px' : '16px'};
    height: ${({ $isLarge = false }) => $isLarge ? '24px' : '16px'};
    margin-right: ${({ $isLarge = false }) => $isLarge ? '10px' : '6px'};
`;
