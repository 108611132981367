import React, {FC} from 'react';
import {Box} from "@mui/material";
import {capitalizeFirstLetter} from "../../../../../../utils";
import {
    InfoIcon,
    InfoIconRollover, InfoIconWrapper,
    Value,
    ValueBold
} from "components/pages/ChemicalDetails/parts/Tabs/Classification/styled";

type Props = {
    value: 'high' | 'medium' | 'low';
}

export const ReliabilityValue: FC<Props> = ({value}) => {
    return (
        <Box display='flex' gap='10px' alignItems='center' position='relative'>
            <Value style={{color: `${value === 'high' ? '#008d58' : (value === 'low' ? '#dc661e' : '#004994')}`}}>
                {capitalizeFirstLetter(value)}
            </Value>
            <InfoIconWrapper>
                <InfoIcon/>
                <InfoIconRollover>
                    <b style={{fontSize: '110%'}}>Reliability Scoring</b>
                    <p><b style={{color: '#008d58'}}>Reliability High</b>: Harmonized GHS classifications were determined by country or regional authorities or used transparent criteria to harmonize reported information.</p>
                    <p><b style={{color: '#004994'}}>Reliability Medium</b>: Independent experts used regional and country specific information and requirements to arrive at the classification and labelling. However the criteria and information used to generate the record could not be independently verified at this time. Future work will verify and correct the record where necessary using objective and transparent criteria.</p>
                    <p><b style={{color: '#dc661e'}}>Reliability Low</b>: Self-reported classifications and labelling have not been validated or assessed by independent experts. Disagreements in classifications and labelling were commonly reported and future work is needed to harmonize the classification and labelling.</p>
                </InfoIconRollover>
            </InfoIconWrapper>
        </Box>
    );
};
