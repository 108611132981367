import styled from "styled-components";
import {ReactComponent as Info} from "assets/images/icons/info.svg";
import {Rollover} from "components/pages/Chemical/parts/Aside/styled";

export const Jurisdiction = styled.h3`
    margin: 0 0 6px 0;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    font-family: ${(props) => props.theme.fontFamilySecondary};
`;

export const Table2Col = styled.div`
    display: grid;
    grid-template-columns: 155px 1fr;
    margin-bottom: 10px;
    gap: 10px;
`;

export const Table3Col = styled.div`
    display: grid;
    grid-template-columns: 150px 2fr 2fr;
    max-width: 800px;
    margin-bottom: 20px;
    border-top: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
`;

export const Value = styled.div`
    font-size: 14px;
    font-weight: 400;
`;

export const ValueBold = styled(Value)`
    font-weight: 600;
`;

export const Value3ColTable = styled(Value)`
    padding: 12px;
    border-right: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    white-space: break-spaces;
`;

export const TableHeaderValue = styled(Value3ColTable)`
    font-size: 16px;
    font-weight: 400;
    background-color: #e0fff4;
    text-align: center;
`;

export const InfoIconRollover = styled(Rollover)`
    width: 400px;
    max-width: 400px;
    padding-bottom: 0;
    &:before {
        left: calc(11%);
    }
`;

export const InfoIcon = styled(Info)`
    cursor: pointer;
`;

export const InfoIconWrapper = styled.div`
    display: flex;
    align-items: center;
    
    &:hover {
        ${InfoIconRollover} {
            display: block;
        }
    }
`;