import React, {useEffect, useState} from 'react';
import BaseModal from "./BaseModal";
import DatabaseImg from 'assets/images/database.png';
import {Checkbox} from "components/controls";
import {Box} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../hooks/global";
import {AgreeText, AgreeTextBold, AgreeTextHeader} from "components/common/Modal/styled";
import {fetchUserAgree} from "../../../store/user/userSlice";

const ConfirmationModal = () => {
    const {role, user} = useAppSelector(state => state.auth);
    const [isModalOpen, setModalOpen] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (user && (role === 'user' || role === 'staff') && !user.profile.is_agree) {
            setModalOpen(true);
        }
    }, [user]);

    const handleModalClose = () => {
        setModalOpen(false);
    }

    const handleAgree = () => {
        setModalOpen(false);
        dispatch(fetchUserAgree()).then();
    }

    return (
        <BaseModal
            isOpen={isModalOpen}
            isHeader={false}
            onClose={handleModalClose}
            maxWidth={840}
            height="90vh"
            maxHeight="90vh"
            overflowX="auto"

        >
            <Box pt='20px'>
                <AgreeTextHeader>
                    Work-in Progress Notice
                </AgreeTextHeader>
                <AgreeText>
                    Thank you for visiting our site! Please note that you are exploring a beta version of the ICCA database.
                    While we&apos;ve put a lot of effort into making it functional and have populated it with confirmed chemicals used as plastic additives,
                    comprehensive quality assurance or quality control (QA/QC) checks of the information are still underway
                    and may result in changes to the information.
                </AgreeText>
                <AgreeText>
                    In the current version of the database, the chemicals confirmed as plastic additives were determined
                    according to the figure below.  Future updates will include data gathered from the SpecialChem database
                    and additional industry input.
                </AgreeText>
                <Box display='flex' justifyContent='center'>
                    <img src={DatabaseImg} width={600} alt=''/>
                </Box>
                <AgreeText>
                    As we continue to improve and refine the database, there may be minor performance issues or incomplete sections.
                    We appreciate your understanding and patience during this stage. If you encounter any issues or have suggestions
                    for improvement, please let us know at registrar@mail.icca-chem.org
                </AgreeText>
                <Checkbox
                    name={'agree'}
                    onClick={handleAgree}
                    label={<AgreeTextBold>Please check here to acknowledge review of this notice before proceeding to the database.</AgreeTextBold>}
                />
            </Box>
            {/*<Button*/}
            {/*    onClick={handleAgree}*/}
            {/*    title='I agree & would like to proceed'*/}
            {/*    color={'green'}*/}
            {/*    variant='secondary'*/}
            {/*    size='XXXXL'*/}
            {/*/>*/}
        </BaseModal>
    );
};

export default ConfirmationModal;
