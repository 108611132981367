import React, {FC} from 'react';
import {IGHS} from "types";
import {
    ClassificationGHSTable,
    GroupHeader,
    Label,
    TitleCell,
    ValueCell
} from "components/pages/ChemicalDetails/parts/Tabs/styled";

type Props = {
    ghs: IGHS;
}

export const GHS: FC<Props> = ({ghs}) => {
    return (
        <>

            <ClassificationGHSTable>
                <TitleCell>
                    <Label>Note</Label>
                </TitleCell>
                <ValueCell>
                    {ghs.note}
                </ValueCell>

                <TitleCell>
                    <Label>Signal</Label>
                </TitleCell>
                <ValueCell>
                    {ghs.signal}
                </ValueCell>

                <TitleCell>
                    <Label>GHS Hazard Statements</Label>
                </TitleCell>
                <ValueCell>
                    {ghs.statements}
                </ValueCell>

                <TitleCell>
                    <Label>ECHA C&L Notifications Summary</Label>
                </TitleCell>
                <ValueCell>
                    {ghs.summary}
                </ValueCell>
            </ClassificationGHSTable>
        </>
    );
};
