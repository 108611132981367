import React, {FC} from 'react';
import Modal from 'react-modal';
import crossIcon from 'assets/images/icons/cross_black.png';
import {CloseBtn, CloseIcon, Content, Header} from "./styled";

interface Prop extends Modal.Props{
    onClose?: any;
    title?: string;
    children?: any;
    maxWidth?: number;
    isHeader?: boolean;
    contentStyles?: React.CSSProperties;
    overlayStyles?: React.CSSProperties;
    headerStyles?: React.CSSProperties;
    height?: string;
    maxHeight?: string;
    overflow?: 'auto' | 'hidden' | 'scroll' | 'visible';
    overflowX?: 'auto' | 'hidden' | 'scroll' | 'visible';
    overflowY?: 'auto' | 'hidden' | 'scroll' | 'visible';
}

type CustomStyles = {
    content: Partial<React.CSSProperties>;
    overlay: Partial<React.CSSProperties>;
};

const BaseModal: FC<Prop> = ({
        isOpen = false,
        isHeader = true,
        onClose,
        title,
        children,
        maxWidth = 560,
        overlayStyles={},
        contentStyles={},
        headerStyles={},
        height = 'auto',
        maxHeight = 'none',
        overflow,
        overflowX = 'hidden',
        overflowY = 'auto',
        ...props
    }) => {

    const customStyles: CustomStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            maxWidth: maxWidth,
            height,
            maxHeight,
            borderRadius: 4,
            border: 'none',
            display: 'flex',
            flexDirection: 'column',
            ...contentStyles,
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 9999,
            ...overlayStyles,
        },
    };

    const contentComponentStyles = {
        flex: 1,
        overflow: overflow || undefined,
        overflowX: overflow ? undefined : overflowX,
        overflowY: overflow ? undefined : overflowY,
        padding: '20px',
    };

    return (
        <Modal
            isOpen={isOpen}
            appElement={document.getElementById('root') || undefined}
            style={customStyles}
            {...props}
        >
            {isHeader && (
                <Header style={headerStyles}>
                    <h2>{title}</h2>
                    <CloseBtn onClick={onClose}>
                        <CloseIcon src={crossIcon} alt={'X'}/>
                    </CloseBtn>
                </Header>
            )}
            <Content style={contentComponentStyles}>
                {children}
            </Content>
        </Modal>
    );
};

export default BaseModal;
