import styled from "styled-components";
import {Button} from "../../controls";
import {defaultTheme} from "../../../themes/main";

export const Header = styled.div`
  min-height: 40px;
  text-align: left;
  padding-left: 30px;
  position: relative;
  &>h2 {
    font-size: 24px;
    color: #232e3f;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 40px;
`;

export const CloseBtn = styled.div`
  background-color: transparent;
  padding: 5px;
  position: absolute;
  right: 10px;
  top: 10px;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const CloseIcon = styled.img`
  width: 25px;
  height: 25px;
  opacity: 0.4;
`;


// Alert //

export const AlertHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Success = styled.h2`
  padding: 0;
  margin: 0;
  color: #00a33d;
  font-size: 24px;
  font-weight: 500;
`;

export const Warning = styled.h2`
  padding: 0;
  margin: 0;
  color: #ffac00;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  line-height: 24px;
`;

export const Error = styled.h2`
  padding: 0;
  margin: 0;
  color: #ff001c;
  font-size: 24px;
  font-weight: 500;
`;

export const AlertContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 20px 10px 20px 10px;
  padding: 0 10px 0 10px;
  font-size: 14px;
  font-weight: 400;
`;

export const AlertFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 25px;
`;

export const ConfirmationAlertFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 60px 0 30px 0;
  padding-left: 20px;
  padding-right: 20px;
`;

export const SuccessMessage = styled.div`
  margin-bottom: 20px;
  text-align: center;
  font-weight: 500;
  line-height: 1.4;
  width: 100%;
`;

export const ErrorMessage = styled.div`
  text-align: center;
  font-weight: 500;
  line-height: 1.4;
  width: 100%;
`;

export const ErrorsList = styled.ul`
  margin: 10px 0 0 0;
  padding: 0;
  list-style-type: none;
`;

export const ErrorItem = styled.li`
  margin-top: 5px;
`;

export const OkBtn = styled(Button)`
  background-color: ${defaultTheme.greenColor};
`;

export const AgreeText = styled.p`
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
`;

export const AgreeTextBold = styled(AgreeText)`
  font-weight: 700;
`;

export const AgreeTextHeader = styled(AgreeTextBold)`
    font-size: 18px;
    font-weight: 700;
`;