import styled from "styled-components";

export const GroupHeader = styled.h3`
  margin: 0 0 16px 0;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  font-family: ${(props) => props.theme.fontFamilySecondary};

  &:not(:first-child) {
    margin-top: 40px;
  }
`;

export const DefaultTable = styled.div`
  display: grid;
  grid-template-columns: 250px 3fr;

  &>div:last-child  {
    border-bottom: 1px solid #f0f0f0;
  }
`;

export const TitleCell = styled.div<{isForm?: boolean}>`
  min-width: 50px;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 15px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  background-color: #FAFAFA;
  border-top: 1px solid #F0F0F0;
  font-size: 14px;

  ${({isForm}) => isForm && (
    {justifyContent: 'center'}
)}
`;

export const MergedTitleCell = styled(TitleCell)`
    grid-column: 1;
`;

export const Label = styled.div`
  color: ${(props) => props.theme.greyTextColor};
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
`;

export const ValueCell = styled(TitleCell)`
  flex-direction: row;
  min-width: 100px;
  background-color: white;
  border-left: 1px solid #F0F0F0;
  white-space: pre-wrap;
  align-items: center;
`;

export const MergedValueCell = styled(ValueCell)`
    grid-column: 2 / 4;
    justify-content: center;
`;

export const Value = styled.div`
  font-weight: 500;
  color: #000;
  white-space: pre-wrap;
  line-height: 1;
  word-break: break-all;
`;

export const Desc = styled.div`
  margin-top: 4px;
  color: ${(props) => props.theme.greyTextColor};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
`;

export const SubstanceImageWrapper = styled(ValueCell)`
  padding: 0;
  display: flex;
  height: 100px;
  width: 100% - 10px;
  border: none;
`;

export const SubstanceImg = styled.img`
  height: 100px;
  cursor: pointer;
  transition: 0.3s;
  object-fit: contain;

  &:hover {
    transform: scale(2.5);
    border: 1px solid lightgray;
    background-color: white;
    z-index: 20;
  }
`;

export const TwoColumnsValueWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
`

export const ChemPropertiesTable = styled.div`
  display: grid;
  grid-template-columns: 250px repeat(4, 1fr);
  border-top: 1px solid #F0F0F0;

  &>div:last-child  {
    //border-bottom: 1px solid #f0f0f0;
  }
`;

export const WideTableHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #e0fff4;
  font-size: 16px;
`;

export const ComingSoon = styled.div`
  margin-top: 8px;
  font-size: 16px;
  font-weight: 700;
  color: #004994;
`;

export const OverviewUseTable = styled.div`
  display: grid;
  grid-template-columns: 250px 400px 400px;

  &>div:last-child  {
    //border-bottom: 1px solid #f0f0f0;
  }
`;

export const ClassificationTable = styled.div`
  display: grid;
  grid-template-columns: 250px 350px;

`;

export const ClassificationGHSTable = styled.div`
  display: grid;
  grid-template-columns: 150px 650px;
  width: fit-content;
  border-left: 1px solid #F0F0F0;
  border-right: 1px solid #F0F0F0;
  border-bottom: 1px solid #F0F0F0;
`;

export const P = styled.p`
  padding-left: 20px;
  &>:first-child {
    margin-right: 10px;
  }
`

export const CitationRow = styled.div`
  display: flex;
  align-items: flex-start;  
  gap: 10px;  
  margin-left: 20px;
  margin-bottom: 10px;
  &>:first-child {
    width: 20px;
  }  
`

export const CitationText = styled.div`
  display: flex;
  width: 100%;
  margin-top: -2px;
  line-height: 20px;
`

export const RiskImg = styled.img`
  height: 400px;
  width: 600px;
`

export const TwoColumnsValueCell = styled.div<{isBold?: boolean}>`
  padding: 4px;
  font-weight: ${(props) => props.isBold ? '700' : '500'};
`;

export const Rollover = styled.div`
  display: none;
  position: absolute;
  top: calc(100% + 12px);
  left: 0;
  padding: 5px;
  max-width: 150px;
  background-color: #EBF0F5;
  border-radius: 4px;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 14px;
  z-index: 2;

  &:before {
    background-color: #EBF0F5;
    content: ' ';
    position: absolute;
    width: 12px;
    height: 12px;
    rotate: 45deg;
    top: -6px;
    left: 15px;
  }
`;

export const EvidenceWrapper = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
  &:hover {
    ${Rollover} {
      display: block;
    }
  }
`;

export const EvidenceText = styled.span`
`;
